<template>
	<el-container class="home_container">
		<!-- 左侧 -->
		<el-aside :width="showMenu ? '64px' : '220px'">
			<div class="aside_head">
				<img src="@/assets/img/logo/fangdian72X72.png" alt="" /><span v-show="!showMenu">后台管理</span>
			</div>
			<el-menu background-color="#191A20" text-color="#DADADA" active-text-color="#fff" unique-opened :collapse="isCollapse" :default-active="activePath" :router="true">
				<el-submenu v-for="(item,index) in menu_list" :key="index" :index="index+''">
					<template slot="title">
						<i :class="item.menu_icon"></i>
						<span>{{item.menu_name}}</span>
					</template>
					<el-menu-item v-for="(sub,sub_index) in item.children" :key="sub_index" :index="sub.menu_luyou" @click="addTabs(sub.menu_luyou, sub.menu_name, item.menu_name)">
						<template slot="title">
							<i :class="sub.menu_icon"></i>
							<span>{{sub.menu_name}}</span>
						</template>
					</el-menu-item>
				</el-submenu>
			</el-menu>
		</el-aside>
		<el-container class="body_container">
			<el-header height="100px">
				<div class="headers">
					<div class="headers_breadcrumb">
						<i v-show="!showMenu" @click="changeCollapse" class="iconfont icon-shouqicaidan showMenu"></i>
						<i v-show="showMenu" @click="changeCollapse" class="iconfont icon-zhankaicaidan showMenu"></i>
						<el-breadcrumb separator-class="el-icon-arrow-right">
							<div v-show="tabsname == '/data_monitor'" style="font-weight: bold">
								监控页
							</div>
							<div v-show="tabsname !== '/data_monitor'">
								<el-breadcrumb-item :to="{ path: '/data_monitor' }">监控页</el-breadcrumb-item>
								<el-breadcrumb-item>{{ breadcrumbObj.parentName }}
								</el-breadcrumb-item>
								<el-breadcrumb-item>{{ breadcrumbObj.title }}
								</el-breadcrumb-item>
							</div>
						</el-breadcrumb>
					</div>
					<!--<div class="headers_breadcrumb">
						<el-button type="warning" size="small" @click="getNewOrder">新订单</el-button>
					</div>-->
					<div class="headers_profile">
						<div class="avatar">
							<el-button type="warning" size="small" v-if="$store.state.userInfo.admin_id==1" @click="goTx" class="tx">提现</el-button>
<!--							<el-button type="danger" size="small" v-if="$store.state.userInfo.admin_id==1" @click="goCz" class="tx">充值(&yen;{{detail.keyong_money}})</el-button>-->
							<!--<el-dropdown trigger="click">
				                <el-badge :value="12" class="item">
													<i class="el-icon-message-solid Badge"></i>
				                </el-badge>
				                <el-dropdown-menu slot="dropdown" style="width: 200px">
				                  <el-dropdown-item style="border-bottom: 2px solid #f6f6f6"
				                    >通知</el-dropdown-item>
				                  <el-dropdown-item
				                    v-for="i in 5"
				                    :key="i"
				                    style="color: #c0c4cc"
				                    >恭喜您中了100个w</el-dropdown-item
				                  >
				                </el-dropdown-menu>
			              	</el-dropdown>-->
							<el-dropdown trigger="click">
<!--                <el-avatar class="el-dropdown-link" :size="40" fit="fill" :src="circleUrl" v-if="circleUrl"></el-avatar>-->
                <el-tag class="el-dropdown-link name-link" type="primary">{{ $store.state.userInfo.admin_name }}</el-tag>
								<el-dropdown-menu slot="dropdown">
									<el-dropdown-item @click.native="editUser">修改密码</el-dropdown-item>
									<el-dropdown-item @click.native="Logout">退出登录</el-dropdown-item>
								</el-dropdown-menu>
							</el-dropdown>
						</div>
					</div>
				</div>
				<div class="tab">
					<el-tabs v-model="tabsname" type="card" closable @tab-remove="removeTab" @tab-click="chooseTab">
						<el-tab-pane v-for="item in tabs" :key="item.name" :label="item.title" :name="item.name">
						</el-tab-pane>
					</el-tabs>
					<el-dropdown trigger="click" class="moreBtn" size="mini" split-button type="primary">
						More
						<el-dropdown-menu slot="dropdown">
							<el-dropdown-item @click.native="cloaseAll">关闭全部</el-dropdown-item>
							<el-dropdown-item @click.native="cloaseCurrent">关闭当前</el-dropdown-item>
							<el-dropdown-item @click.native="cloaseOther">关闭其他</el-dropdown-item>
						</el-dropdown-menu>
					</el-dropdown>
				</div>
			</el-header>
			<el-main>
				<transition name="fade">
					<router-view />
				</transition>

			</el-main>
		</el-container>

		<el-dialog title="编辑账户" :visible.sync="showEditUserDialog" width="30%">
			<el-form :model="editform" label-width="100px">
				<el-form-item label="账号">
					<el-input v-model="editform.username" disabled="disabled"></el-input>
				</el-form-item>
				<el-form-item label="新密码">
					<el-input v-model="editform.newPassword" placeholder="请输入新密码"></el-input>
				</el-form-item>
				<el-form-item label="确认新密码">
					<el-input v-model="editform.confirmNewPassword" placeholder="请确认新密码"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="showEditUserDialog = false">取 消</el-button>
				<el-button type="primary" @click="editUserInfo">确 定</el-button>
			</div>
		</el-dialog>

		<!-- 添加城市对话框 -->
		<el-dialog title="平台提现" :visible.sync="txVisible" width="600px">
			<el-form class="left" :rules="txRules" ref="txForm" :model="txData" label-width="150px">
				<el-row>
					<el-col :span="22">
						<el-form-item label="管理员名称" prop="admin_name">
							<el-input v-model="txData.admin_name" :disabled="true"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="22">
						<el-form-item label="heepay当前账号余额" prop="adapay_keyong">
							<el-input v-model="txData.adapay_keyong" :disabled="true"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="22">
						<el-form-item label="平台当前可提现金额" prop="keyong_total">
							<el-input v-model="txData.keyong_total" :disabled="true"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="22">
						<el-form-item label="提现金额" prop="tixian_total">
							<el-input v-model.number="txData.tixian_total" placeholder="请输入您想提现的金额" @input="numberChange(arguments[0], txData.keyong_total, 'tixian_total')" @change="numberChange(arguments[0], txData.keyong_total, 'tixian_total')"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
			<div class="center">
				<el-button type="default" @click="txVisible=false">取消</el-button>
				<el-button type="primary" @click="txSubmit">确认</el-button>
			</div>
		</el-dialog>

    <!-- 平台充值 -->
    <el-dialog title="平台充值" :visible.sync="czVisible" width="600px">
      <el-form class="left" :rules="czRules" ref="czForm" :model="czData" label-width="150px">
        <el-row>
          <el-col :span="22">
            <el-form-item label="管理员名称">
              <el-input v-model="$store.state.userInfo.admin_name" :disabled="true"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="22">
            <el-form-item label="充值金额" prop="chongzhi_amount">
              <el-input v-model="czData.chongzhi_amount" type="number" placeholder="请输入您想提现的金额" oninput="if (value<0.01) value=1"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div class="center">
        <el-button type="default" @click="czVisible=false">取消</el-button>
        <el-button type="primary" @click="czSubmit">确认</el-button>
      </div>
    </el-dialog>
	</el-container>
</template>

<script>
	import music from '@/assets/order.mp3'  //引入音乐文件
	import axios from "axios";
  import {MessageBox} from "element-ui";
	export default {
		data() {
			return {
				screenWidth: document.body.clientWidth,
				isCollapse: false, //侧边栏缩小
				activePath: "/data_monitor", //当前激活菜单
				showMenu: false, //侧边栏缩小
				showEditUserDialog: false,
				tabsname: "/data_monitor", //当前选择tabs页签
				breadcrumbObj: {}, //面包屑路径
				circleUrl: "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
				editform: {
					username: "admin",
					newPassword: "",
					confirmNewPassword: "",
				},
				txVisible: false,
				txData: {},
				txRules: {
					tixian_total: [{
						required: true,
						message: '请输入提现金额',
						trigger: 'blur'
					}, {
						type: "number",
						message: "请输入数字"
					}]
				},
        czVisible: false,
        czData: {
          chongzhi_amount: "",
        },
        czRules: {
          chongzhi_amount: [{required: true, message: '请输入充值金额', trigger: 'blur'}]
        },
				tabs: [{
					title: "监控页",
					name: "/data_monitor",
					parentName: "监控页",
				}, ],
				menu_list: [{
						menu_name: "系统管理",
						menu_icon: "iconfont icon-fuwuguanli",
						children: [{
								menu_name: "管理员管理",
								menu_luyou: "/admin_manage",
								menu_icon: "el-icon-user-solid"
							},
							{
								menu_name: "角色管理",
								menu_luyou: "/role_manage",
								menu_icon: "el-icon-postcard"
							},
							{
								menu_name: "菜单管理",
								menu_luyou: "/menu_manage",
								menu_icon: "el-icon-s-operation"
							},
							{
								menu_name: "部门管理",
								menu_luyou: "/dept_manage",
								menu_icon: "el-icon-office-building"
							},
							{
								menu_name: "版本管理",
								menu_luyou: "/version_settings",
								menu_icon: "el-icon-menu"
							},
							{
								menu_name: "系统设置",
								menu_luyou: "/sys_settings",
								menu_icon: "el-icon-s-tools"
							},
							{
								menu_name: "城市管理",
								menu_luyou: "/other_settings",
								menu_icon: "el-icon-s-tools"
							},
							{
								menu_name: "调研表单",
								menu_luyou: "/form_setting",
								menu_icon: "el-icon-s-order"
							},
							{
								menu_name: "登录日志",
								menu_luyou: "/login_log",
								menu_icon: "el-icon-date"
							},
						]
					},
					{
						menu_name: "服务管理",
						menu_icon: "iconfont icon-fuwuguanli",
						children: [{
								menu_name: "服务列表",
								menu_luyou: "/service_add",
								menu_icon: "el-icon-menu"
							},
							{
								menu_name: "服务类型",
								menu_luyou: "/service_type",
								menu_icon: "el-icon-s-operation"
							},
							{
								menu_name: "轮播图设置",
								menu_luyou: "/service_banner",
								menu_icon: "el-icon-picture"
							}
						]
					},
					{
						menu_name: "技师管理",
						menu_icon: "iconfont icon-jishiguanli",
						children: [{
								menu_name: "技师列表",
								menu_luyou: "/technician_manage",
								menu_icon: "el-icon-menu"
							},
							{
								menu_name: "技师招聘",
								menu_luyou: "/technician_zhaopin",
								menu_icon: "el-icon-menu"
							},
							{
								menu_name: "技师等级",
								menu_luyou: "/technician_level",
								menu_icon: "el-icon-menu"
							},
							{
								menu_name: "技师门派",
								menu_luyou: "/technician_sect",
								menu_icon: "el-icon-menu"
							},
							{
								menu_name: "问题反馈",
								menu_luyou: "/technician_yijian",
								menu_icon: "el-icon-chat-dot-round"
							},
							{
								menu_name: "信息审核",
								menu_luyou: "/technician_verify",
								menu_icon: "el-icon-edit-outline"
							},
						]
					},
					{
						menu_name: "用户管理",
						menu_icon: "iconfont icon-huiyuan",
						children: [{
								menu_name: "用户列表",
								menu_luyou: "/user_list",
								menu_icon: "el-icon-menu"
							},
							{
								menu_name: "意见反馈",
								menu_luyou: "/user_yijian",
								menu_icon: "el-icon-menu"
							},
						]
					},
					{
						menu_name: "会员管理",
						menu_icon: "iconfont icon-huiyuan",
						children: [{
								menu_name: "会员列表",
								menu_luyou: "/vip_list",
								menu_icon: "el-icon-menu"
							},
							{
								menu_name: "会员等级",
								menu_luyou: "/vip_level",
								menu_icon: "el-icon-menu"
							},
							{
								menu_name: "会员充值",
								menu_luyou: "/vip_chongzhi",
								menu_icon: "el-icon-menu"
							},
							{
								menu_name: "会员预警",
								menu_luyou: "/vip_warning",
								menu_icon: "el-icon-menu"
							},
						]
					},
					{
						menu_name: "代理管理",
						menu_icon: "iconfont icon-dailishezhi",
						children: [{
								menu_name: "代理列表",
								menu_luyou: "/agent_add",
								menu_icon: "el-icon-menu"
							},
							{
								menu_name: "代理招聘",
								menu_luyou: "/agent_zhaopin",
								menu_icon: "el-icon-menu"
							},
						]
					},
					{
						menu_name: "经理管理",
						menu_icon: "iconfont icon-dailishezhi",
						children: [{
							menu_name: "经理列表",
							menu_luyou: "/jingli_list",
							menu_icon: "el-icon-menu"
						}, ]
					},
					{
						menu_name: "推广管理",
						menu_icon: "iconfont icon-huodongtuiguang",
						children: [{
								menu_name: "APP推广",
								menu_luyou: "/spread_App",
								menu_icon: "el-icon-menu"
							},
							{
								menu_name: "优惠券",
								menu_luyou: "/spread_coupon",
								menu_icon: "el-icon-menu"
							},
							{
								menu_name: "推广列表",
								menu_luyou: "/spread_list",
								menu_icon: "el-icon-menu"
							},
						]
					},
					{
						menu_name: "订单管理",
						menu_icon: "iconfont icon-dingdan",
						children: [{
								menu_name: "订单列表",
								menu_luyou: "/order_list",
								menu_icon: "el-icon-menu"
							},
							{
								menu_name: "退款管理",
								menu_luyou: "/order_refund",
								menu_icon: "el-icon-menu"
							},
							{
								menu_name: "评价标签",
								menu_luyou: "/order_commentLabel",
								menu_icon: "el-icon-menu"
							},
							{
								menu_name: "评价管理",
								menu_luyou: "/order_commentManage",
								menu_icon: "el-icon-menu"
							},
							{
								menu_name: "客户投诉",
								menu_luyou: "/order_tousu",
								menu_icon: "el-icon-warning"
							},
							{
								menu_name: "技师评价",
								menu_luyou: "/order_jspj",
								menu_icon: "el-icon-s-claim"
							},
						]
					},
					{
						menu_name: "数据统计",
						menu_icon: "iconfont icon-fuwuguanli",
						children: [{
								menu_name: "代理人",
								menu_luyou: "/agent_tj",
								menu_icon: "el-icon-s-custom"
							},
							{
								menu_name: "经理人",
								menu_luyou: "/manager_tj",
								menu_icon: "el-icon-s-custom"
							},
						]
					},
					{
						menu_name: "财务管理",
						menu_icon: "el-icon-bank-card",
						children: [{
							menu_name: "提现管理",
							menu_luyou: "/tixian_manage",
							menu_icon: "el-icon-bank-card"
						}, ]
					},
					{
						menu_name: "物料商城",
						menu_icon: "el-icon-goods",
						children: [{
								menu_name: "商城设置",
								menu_luyou: "/thing_setting",
								menu_icon: "el-icon-menu"
							},
							{
								menu_name: "商品列表",
								menu_luyou: "/thing_goods",
								menu_icon: "el-icon-menu"
							},
							{
								menu_name: "商品分类",
								menu_luyou: "/thing_category",
								menu_icon: "el-icon-menu"
							},
							{
								menu_name: "商品属性",
								menu_luyou: "/thing_attrtype",
								menu_icon: "el-icon-menu"
							},
							{
								menu_name: "订单列表",
								menu_luyou: "/thing_order",
								menu_icon: "el-icon-menu"
							},
						]
					},
				],
        detail: {}
			};
		},
		watch: {
			screenWidth(val) {
				this.screenWidth = val
				if(this.screenWidth < 1200) {
					this.isCollapse = true
					this.showMenu = true
				} else {
					this.isCollapse = false
					this.showMenu = false
				}
			},
		},
		created() {
			if(!this.$store.state.userInfo) {
				// 防止未登录
				this.$router.push({
					path: '/login'
				})
			} else {
				this.getRoleMenu();
        this.ptMoney();
				//获取当前路由
				let cur_route = this.$route.path;
				//跳转到对应路由页面
				if(cur_route == this.activePath) {
					this.$router.push(this.activePath);
				} else {
					for(let i = 0; i < this.menu_list.length; i++) {
						let sub_list = this.menu_list[i].children;
						for(let j = 0; j < sub_list.length; j++) {
							if(cur_route == sub_list[j].menu_luyou) {
								this.activePath = sub_list[j].menu_luyou;
								this.addTabs(sub_list[j].menu_luyou, sub_list[j].menu_name, this.menu_list[i].menu_name);
								return;
							}
						}
					}
				}
			}
		},
		mounted() {
			let that = this
			window.onresize = () => {
				return(() => {
					window.screenWidth = document.body.clientWidth
					that.screenWidth = window.screenWidth
				})();
			}
		},
		methods: {
			getRoleMenu() {
				const loading = this.$loading({
					lock: true,
					text: 'Loading',
					spinner: 'el-icon-loading',
					background: 'rgba(0, 0, 0, 0.7)'
				});
				var url = 'admin/getRoleMenu';
				let params = {};
				this.fd_post(url, params).then((res) => {
					loading.close();
					if(res.status) {
						this.menu_list = res.list;
						this.circleUrl = res.detail.admin_logo;
					} else {
						this.$message.error(res.msg);
					}
				}).catch((err) => {
					loading.close();
					this.$message.error('网络错误');
				});
			},
			changeCollapse() {
				this.showMenu = !this.showMenu;
				this.isCollapse = !this.isCollapse;
			},
			editUser() {
				this.showEditUserDialog = true;
			},
			editUserInfo() {
				console.log("确认提交");
				this.showEditUserDialog = false;
			},
			//退出
			Logout() {
				let that = this;
				var url = "login/adminLoginOut";
				let params = {};
				this.fd_post(url, params)
					.then((res) => {
						if(res.status) {
							that.$store.commit("$_removeStorage");
							that.$message.success("退出成功");
							that.$router.push("/");
						} else {
							that.$message.error(res.msg);
						}
					})
					.catch((err) => {
						that.$message.error("网络错误");
					});
			},
			// 点击选择tabs页签
			chooseTab(tab) {
				this.tabsname = this.tabs[tab.index].name;
				this.activePath = this.tabs[tab.index].name;
				this.breadcrumbObj.title = this.tabs[tab.index].title;
				this.breadcrumbObj.parentName = this.tabs[tab.index].parentName;
				this.$router.push(this.tabs[tab.index].name).catch(() => {});
			},
			//删除tabs页签
			removeTab(name) {
				for(let i = 1; i < this.tabs.length; i++) {
					if(this.tabs[i].name === name) {
						if(this.tabs.length - 1 === i) {
							this.breadcrumbObj.title = this.tabs[i - 1].title;
							this.breadcrumbObj.parentName = this.tabs[i - 1].parentName;
							this.$router.push(this.tabs[i - 1].name).catch(() => {});
							this.tabsname = this.tabs[i - 1].name;
						}
						if(this.tabs.length - 1 > i) {
							this.breadcrumbObj.title = this.tabs[i + 1].title;
							this.breadcrumbObj.parentName = this.tabs[i + 1].parentName;
							this.$router.push(this.tabs[i + 1].name).catch(() => {});
							this.tabsname = this.tabs[i + 1].name;
						}
						this.tabs.splice(i, 1);
						return;
					}
				}
			},
			//创建tabs页签
			addTabs(name, title, parentName) {
        console.log(name, title, parentName)
				this.tabsname = name;
				this.breadcrumbObj.title = title;
				this.breadcrumbObj.parentName = parentName;
				for(let i = 0; i < this.tabs.length; i++) {
					if(this.tabs[i].name === name) return;
				}
				this.tabs.push({
					name,
					title,
					parentName
				});
			},
			//关闭全部tabs
			cloaseAll() {
				this.tabs.splice(1, this.tabs.length - 1);
				this.$router.push(this.tabs[0].name);
				this.tabsname = this.tabs[0].name;
			},
			//关闭当前tabs
			cloaseCurrent() {
				for(let i = 1; i < this.tabs.length; i++) {
					if(this.tabs[i].name === this.tabsname) {
						this.breadcrumbObj.title = this.tabs[i - 1].title;
						this.breadcrumbObj.parentName = this.tabs[i - 1].parentName;
						this.$router.push(this.tabs[i - 1].name);
						this.tabsname = this.tabs[i - 1].name;
						this.tabs.splice(i, 1);
						return;
					}
				}
			},
			goTx() {
				let that = this;
				var url = "admin/getTxDetail";
				let params = {};
				this.fd_post(url, params)
					.then((res) => {
						if(res.status) {
							this.txData = res.detail;
							this.txVisible = true;
						} else {
							that.$message.error(res.msg);
						}
					})
					.catch((err) => {
						that.$message.error("网络错误");
					});
			},
      goCz(){
        this.czVisible = true;
      },
			numberChange(val, maxNum, name) {
				if(isNaN(Number(val))) {
					this.$message.error("请输入数字");
					this.txData[name] = 0;
					return;
				}
				//转换数字类型
				this.txData[name] = Number(val)
				//重新渲染
				this.$nextTick(() => {
					//比较输入的值和最大值，返回小的
					let num = Math.min(Number(val), maxNum)
					//输入负值的情况下， = 0（可根据实际需求更该）
					if(num < 0) {
						this.txData[name] = 0
					} else {
						//反之
						this.txData[name] = num
					}
				})
			},
			txSubmit() {
				let that = this;
				this.$refs.txForm.validate(valid => {
					if(!valid) return
					var url = 'admin/tixian_shenqing';
					let params = that.txData;
					that.fd_post(url, params).then((res) => {
						if(res.status) {
							that.txVisible = false;
							that.$message.success(res.msg);
						} else {
							that.$message.error(res.msg);
						}
					}).catch((err) => {
						that.$message.error('网络错误');
					});
				})
			},
      ptMoney() {
        this.fd_post('admin/get_chongzhi_detail', {}).then((res) => {
          if(res.status) {
            this.detail = res.data
          }
        }).catch((err) => {
          this.$message.error('网络错误');
        });
      },
      czSubmit() {
        // let pay_info = {
        //   "order_bh": "CZ25022717175330777",
        //   "pay_type": 5,//支付类型 1/微信公众号/2微信小程序/3/支付宝H5支付 4/支付宝App支付 5/微信扫码 6/支付宝扫码
        //   "order_type": 1,//订单类型 1/充值 2/订单 3/物料
        // };
        // this.fd_post('client/order/order_payInfo', pay_info).then((pres)=>{
        //   if(pres.status){
        //     // window.location.href = pres.result;
        //     console.log(pres.result)
        //   }else{
        //     this.$message.error('网络错误');
        //   }
        // });
        // return

        this.$refs.czForm.validate(valid => {
          if(!valid) return
          var url = 'admin/chongzhi_submit';
          let params = this.czData;
          this.fd_post(url, params).then((res) => {
            if(res.status) {
              const pay_type = 5;
              let pay_info = {
                "order_bh": res.chongzhi_bh,
                "pay_type": pay_type,//支付类型 1/微信公众号/2微信小程序/3/支付宝H5支付 4/支付宝App支付 5/微信扫码 6/支付宝扫码
                "order_type": 1,//订单类型 1/充值 2/订单 3/物料
              };
              this.fd_post('client/order/order_payInfo', pay_info).then((pres)=>{
                if(pres.status){
                  if(pay_type===5){
                    // 打开新窗口
                    const newWindow = window.open('', '_blank', 'width=1200,height=800');

                    // 将 HTML 内容写入新窗口的文档中
                    newWindow.document.open();
                    newWindow.document.write(pres.result);
                    newWindow.document.close();
                  }
                }else{
                  this.$message.error('网络错误');
                  return;
                }
              });

            } else {
              this.$message.error(res.msg);
            }
          }).catch((err) => {
            this.$message.error('网络错误');
          });
        })
      },
			//关闭其他tabs
			cloaseOther() {
				let tabsname = this.tabsname;
				this.tabs = this.tabs.filter(
					(item) => item.name === tabsname || item.name === "/data_monitor"
				);
			},
			getNewOrder(){
				//轮询获取服务器数据
	     		window.setInterval(() => {
			     	this.musicPlay();
			   	}, 10000);
			},
			musicPlay(){
				const audio = new Audio(music);
				audio.addEventListener('canplaythrough', () => {
					audio.play();
				});
			}
		},
	};
</script>

<style lang='scss' scoped>
	@import "./Home.scss";
</style>